'use client';
import { useRouter } from 'next/navigation';
import styles from './NotFound.module.scss';
import { Button } from '@/atoms/Button';
import { useTranslations } from 'next-intl';

export default function NotFound() {
  const router = useRouter();
  const t = useTranslations();

  return (
    <div className={styles.wrapper}>
      <div className={styles.sign}>404</div>
      <div className={styles.content}>
        <p className={styles.title}>{t('notFound.title')}</p>
        <p className={styles.description}>{t('notFound.description')}</p>
      </div>
      <div className={styles.buttons}>
        <Button
          text={t('notFound.button')}
          onClick={() => router.push('/')}
          variant="dark"
        />
      </div>
    </div>
  );
}
